/*
Margin and rounding are personal preferences,
overflow-x-auto is recommended.
*/
pre {
  @apply my-4 rounded overflow-x-auto;
}

/*
 13 Add some vertical padding and expand the width
 14 to fill its container. The horizontal padding
 15 comes at the line level so that background
 16 colors extend edge to edge.
 17*/
pre.torchlight code {
  @apply block py-4 min-w-max;
}

/*
 Horizontal line padding to match the vertical
 padding from the code block above.
*/
pre.torchlight code .line {
  @apply px-4 py-1.5;
}

/*
 Push the code away from the line numbers and
 summary caret indicators.
*/
pre.torchlight code .line-number,
pre.torchlight code .summary-caret {
  @apply mr-4;
}
