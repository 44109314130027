/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import "torchlight.css";

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply antialiased dark:bg-black-90;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-black-100 dark:text-white;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-medium;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 3.5rem;
  }

  h2 {
    font-size: 2rem;
    line-height: 3rem;
  }

  h3 {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }

  h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  label {
    @apply block text-regular mb-2 dark:text-white;
  }

  input,
  textarea {
    @apply border border-black-20 dark:border-black-70 dark:bg-black-90 px-3 py-2 rounded-lg text-regular w-full placeholder:text-black-40 dark:placeholder:text-black-70 focus:border-black-70 focus:outline-none focus:ring-1 focus:ring-black-70 resize-none;
  }

  textarea {
    @apply h-32;
  }

  button[type="submit"] {
    @apply bg-black-80 dark:bg-white text-medium text-white dark:text-black-70 px-4 py-2 rounded-lg hover:bg-black-90 dark:hover:bg-black-10 active:bg-black-100 dark:active:bg-black-20 dark:border dark:border-black-20;
  }
}
